<template>
  <div>
    <b-row>
      <b-col
        lg="3"
        sm="6"
      >
        <statistic-card-horizontal
          icon="UsersIcon"
          statistic="414968"
          statistic-title="Количество должников"
        />
      </b-col>
      <b-col
        lg="3"
        sm="6"
      >
        <statistic-card-horizontal
          icon="FileTextIcon"
          color="success"
          statistic="33176"
          statistic-title="Обработано должников"
        />
      </b-col>
      <b-col
        lg="3"
        sm="6"
      >
        <statistic-card-horizontal
          icon="CheckSquareIcon"
          color="danger"
          statistic="58 412 951 632"
          statistic-title="Сумма взыскания"
        />
      </b-col>
      <b-col
        lg="3"
        sm="6"
      >
        <statistic-card-horizontal
          icon="AlertOctagonIcon"
          color="warning"
          statistic="978 545 753 321"
          statistic-title="Общая сумма долгов"
        />
      </b-col>
    </b-row>

    <b-row>
      <b-col
        md="6"
        sm="12"
      >
        <b-card title="Динамика погашения обещаний">
          <b-row style="min-height: 350px">
            <b-col md="12">
              <apexchart
                ref="promisesChart"
                style="overflow-x: auto; overflow-y: hidden"
                type="bar"
                height="350"
                :options="verticalChartOptions"
                :series="verticalSeries"
              />
            </b-col>
          </b-row>
        </b-card>
      </b-col>
      <b-col
        md="6"
        sm="12"
      >
        <b-card title="Динамика закрытия обещаний">
          <b-row style="min-height: 350px">
            <b-col md="12">
              <apexchart
                ref="closedPromisesChart"
                style="overflow-x: auto; overflow-y: hidden"
                type="bar"
                height="350"
                :options="verticalChartOptions2"
                :series="verticalSeries2"
              />
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>

    <b-row>
      <b-col
        sm="12"
        class="px-0"
      >
        <b-card>
          <apexchart
            ref="chart"
            type="bar"
            height="350"
            style="overflow-x: auto"
            :options="chartOptions"
            :series="series"
          />
        </b-card>
      </b-col>
    </b-row>

    <b-row>
      <b-col sm="6">
        <b-card>
          <apexchart
            ref="horizontalBarChart"
            type="bar"
            height="430"
            style="overflow-x: auto"
            :options="horizontalBarChartOptions"
            :series="horizontalBarSeries"
          />
        </b-card>
      </b-col>

      <b-col sm="6">
        <b-card>
          <apexchart
            ref="pieChart"
            type="pie"
            width="700"
            style="overflow-x: auto"
            :options="pieChartOptions"
            :series="pieSeries"
          />
        </b-card>
      </b-col>
    </b-row>

  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
} from 'bootstrap-vue'
// import IEcharts from 'vue-echarts-v3/src/full.js'
// import flatPickr from 'vue-flatpickr-component'
import StatisticCardHorizontal from '@core/components/statistics-cards/StatisticCardHorizontal.vue'
import VueApexCharts from 'vue-apexcharts'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    StatisticCardHorizontal,
    // IEcharts,
    // flatPickr,
    apexchart: VueApexCharts,
  },
  data() {
    return {

      series: [
        {
          name: 'Общая сумма обещаний',
          data: [2000000, 1500000, 1350000, 1200000, 1800000, 2100000, 1800000, 2500000, 2700000, 3000000, 3400000],
        },
        {
          name: 'Закрыто',
          data: [5000000, 3800000, 2500000, 3000000, 3800000, 4000000, 3800000, 3000000, 1800000, 1200000, 200000],
        },
      ],

      chartOptions: {
        chart: {
          type: 'bar',
          height: 350,
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: '55%',
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          show: true,
          width: 2,
          colors: ['transparent'],
        },
        xaxis: {
          categories: ['01.06.2022', '02.06.2022', '03.06.2022', '04.06.2022', '05.06.2022', '06.06.2022', '07.06.2022', '08.06.2022', '09.06.2022'],
        },
        yaxis: {},
        fill: {
          opacity: 1,
        },
        tooltip: {
          y: {
            formatter(val) {
              return new Intl.NumberFormat('ru-Ru', {
                style: 'currency',
                currency: 'UZS',
              }).format(val)
            },
          },
        },
      },

      verticalSeries: [
        {
          name: 'Сумма',
          data: [2000000, 1500000, 1350000, 1200000, 1800000, 2100000, 1800000, 2500000, 2700000, 3000000, 3400000],
        },
      ],
      verticalSeries2: [
        {
          name: 'Сумма',
          data: [5000000, 3800000, 2500000, 3000000, 3800000, 4000000, 3800000, 3000000, 1800000, 1200000, 200000],
        },
      ],

      verticalChartOptions: {
        toolbar: {
          show: false,
          export: {
            csv: {
              filename: undefined,
              columnDelimiter: ',',
              headerCategory: 'category',
              headerValue: 'value',
              dateFormatter(timestamp) {
                return new Date(timestamp).toDateString()
              },
            },
            svg: {
              filename: undefined,
            },
            png: {
              filename: undefined,
            },
          },
          autoSelected: 'zoom',
        },
        chart: {
          width: '100%',
        },
        colors: ['#E91E63', '#008FFB'],
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: '55%',
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          show: true,
          width: 2,
          colors: ['transparent'],
        },
        xaxis: {
          categories: ['01.06.2022', '02.06.2022', '03.06.2022', '04.06.2022', '05.06.2022', '06.06.2022', '07.06.2022', '08.06.2022', '09.06.2022'],
        },
        yaxis: {},
        fill: {
          opacity: 1,
        },
        tooltip: {
          y: {
            formatter(val) {
              return new Intl.NumberFormat('ru-Ru', {
                style: 'currency',
                currency: 'UZS',
              }).format(val)
            },
          },
        },
      },

      verticalChartOptions2: {
        toolbar: {
          show: false,
          export: {
            csv: {
              filename: undefined,
              columnDelimiter: ',',
              headerCategory: 'category',
              headerValue: 'value',
              dateFormatter(timestamp) {
                return new Date(timestamp).toDateString()
              },
            },
            svg: {
              filename: undefined,
            },
            png: {
              filename: undefined,
            },
          },
          autoSelected: 'zoom',
        },
        chart: {
          width: '100%',
        },
        colors: ['#00aedb', '#008FFB'],
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: '55%',
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          show: true,
          width: 2,
          colors: ['transparent'],
        },
        xaxis: {
          categories: ['01.06.2022', '02.06.2022', '03.06.2022', '04.06.2022', '05.06.2022', '06.06.2022', '07.06.2022', '08.06.2022', '09.06.2022'],
        },
        yaxis: {},
        fill: {
          opacity: 1,
        },
        tooltip: {
          y: {
            formatter(val) {
              return new Intl.NumberFormat('ru-Ru', {
                style: 'currency',
                currency: 'UZS',
              }).format(val)
            },
          },
        },
      },

      horizontalBarSeries: [{
        name: '2022',
        data: [
          207,
          135,
          76,
          68,
          86,
          46,
          129,
          154,
          95,
          160,
          134,
          148,
          172,
          576,
        ],
      }],
      horizontalBarChartOptions: {
        chart: {
          type: 'bar',
          height: 430,
        },
        plotOptions: {
          bar: {
            horizontal: true,
            dataLabels: {
              position: 'top',
            },
          },
        },
        dataLabels: {
          enabled: true,
          offsetX: -6,
          style: {
            fontSize: '12px',
            colors: ['#fff'],
          },
        },
        stroke: {
          show: true,
          width: 1,
          colors: ['#fff'],
        },
        tooltip: {
          shared: true,
          intersect: false,
        },
        xaxis: {
          categories: [
            'Абдуллаева Доно',
            'Сайлиева Хафиза',
            'Якубова Дилдора',
            'Исмаилова Нодира',
            'Маннопова Насиба',
            'Сотволдиева Азизахон',
            'Исламходжаева Хилола',
            'Гулямова Севара',
            'Бердибекова Покиза',
            'Кадирова Мухаббат',
            'Юлдашева Лобар',
            'Агзамова Севара',
            'Atajanova Nadira Tayirxanovna',
            'Упущенные',
          ],
        },
      },

      pieSeries: [
        207,
        135,
        76,
        68,
        86,
        46,
        129,
        154,
        95,
        160,
        134,
        148,
        172,
        576,
      ],
      pieChartOptions: {
        chart: {
          width: 500,
          type: 'pie',
        },
        labels: [
          'Абдуллаева Доно',
          'Сайлиева Хафиза',
          'Якубова Дилдора',
          'Исмаилова Нодира',
          'Маннопова Насиба',
          'Сотволдиева Азизахон',
          'Исламходжаева Хилола',
          'Гулямова Севара',
          'Бердибекова Покиза',
          'Кадирова Мухаббат',
          'Юлдашева Лобар',
          'Агзамова Севара',
          'Atajanova Nadira Tayirxanovna',
          'Упущенные',
        ],
        responsive: [{
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              position: 'bottom',
            },
          },
        }],
      },

    }
  },
}
</script>
